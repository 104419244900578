import axios from "axios";
import qs from "qs";
import config from "../auth_config.json";
import i18n from "../languages/i18n";
import i18next from "i18next";

const t = i18next.t.bind(i18next);

const getBrowserLanguage = () => {
  return i18n.language.substring(0, 2);
};

const getLanguageParam = (state) => {
  var browserLanguage = getBrowserLanguage();
  return state.options.browserFilter ? browserLanguage : null;
};

export const ACTION_TYPES = {
  ADD_BOOK_TO_CART: "ADD_BOOK_TO_CART",
  AUTHORS_ADDED: "AUTHORS_ADDED",
  AUTHORS_LOADED: "AUTHORS_LOADED",
  AUTHORS_SEARCH_CRITERIA: "AUTHORS_SEARCH_CRITERIA",
  BOOK_LANGUAGES_LOADED: "BOOK_LANGUAGES_LOADED",
  BOOK_SERIES_LOADED: "BOOK_SERIES_LOADED",
  BOOK_TOPICS_LOADED: "BOOK_TOPICS_LOADED",
  BROWSER_FILTER_CHANGE: "BROWSER_FILTER_CHANGE",
  CART_POSTED: "CART_POSTED",
  CHANGE_CART_QTY: "CHANGE_CART_QTY",
  CHANGE_CHECKOUT_FORM: "CHANGE_CHECKOUT_FORM",
  CHANGE_NEW_ADDRESS_FORM: "CHANGE_NEW_ADDRESS_FORM",
  CHANGE_SUBSCRIPTIONS_FORM: "CHANGE_SUBSCRIPTIONS_FORM",
  CHANGE_USER_FORM: "CHANGE_USER_FORM",
  CLEAN_CART: "CLEAN_CART",
  CLEAN_CHECKOUT_FORM: "CLEAN_CHECKOUT_FORM",
  CLEAN_NEW_ADDRESS_FORM: "CLEAN_NEW_ADDRESS_FORM",
  CLEAN_SUBSCRIPTION: "CLEAN_SUBSCRIPTION",
  CLEAN_USER_FORM: "CLEAN_USER_FORM",
  GET_BOOKS: "GET_BOOKS",
  LITERATURE_ADDED: "LITERATURE_ADDED",
  LITERATURE_LOADED: "LITERATURE_BOOKS_LOADED",
  LITERATURE_SEARCH_CRITERIA: "LITERATURE_SEARCH_CRITERIA",
  MAIN_SEARCH_CRITERIA: "MAIN_SEARCH_CRITERIA",
  MAIN_SEARCH_QUERY_START: "MAIN_SEARCH_QUERY_START",
  MAIN_SEARCH_QUERY_END: "MAIN_SEARCH_QUERY_END",
  NEW_MESSAGE: "NEW_MESSAGE",
  NOTICE_LOADED: "NOTICE_LOADED",
  OPEN_MODAL: "OPEN_MODAL",
  ORDERS_LOADED: "ORDERS_LOADED",
  REGULARGIVING_ADDED: "REGULARGIVING_ADDED",
  REGULARGIVING_CANCELLED: "REGULARGIVING_CANCELLED",
  REMOVE_BOOK_FROM_CART: "REMOVE_BOOK_FROM_CART",
  SELECTED_BOOK_CHANGED: "SELECTED_BOOK_CHANGED",
  SEARCH_AUDIO_RESULTSET: "SEARCH_AUDIO_RESULTSET",
  SEARCH_COURSE_RESULTSET: "SEARCH_COURSE_RESULTSET",
  SEARCH_LITERATURE_RESULTSET: "SEARCH_LITERATURE_RESULTSET",
  SORT_AUTHOR_CHANGE: "SORT_AUTHOR_CHANGE",
  USER_LOADED: "USER_LOADED",
  ADDRESSES_LOADED: "ADDRESSES_LOADED",
  TOKEN_LOADED: "TOKEN_LOADED",
};

export const startMainSearch = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.MAIN_SEARCH_QUERY_START,
    });
  };
};
export const endMainSearch = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.MAIN_SEARCH_QUERY_END,
    });
  };
};

export const getAllByCriteria = (criteria) => {
  return async (dispatch, getState) => {
    await dispatch(getBooksByCriteria({ input: criteria }, true));
    await dispatch(getAudiosByCriteria(criteria));
    await dispatch(getCoursesByCriteria(criteria));

    const laterState = getState();
    const { searchLiterature, searchAudio, searchCourses } = laterState.search;
    if (
      searchLiterature.length < 1 &&
      searchAudio.length < 1 &&
      searchCourses.length < 1
    ) {
      const message = t("search-no-result") + ": " + criteria;
      dispatch(sendMessage(message, "", "danger"));
    }
  };
};

export const getBooksByCriteria = (criteria, literalSeach) => {
  return (dispatch, getState) => {
    dispatch({
      type: ACTION_TYPES.MAIN_SEARCH_CRITERIA,
      payload: literalSeach ? criteria : { ...getState().search.mainFilter },
    });

    const params = { ...getState().search.mainFilter };
    Object.keys(params).forEach((key) => {
      if (params[key] === null || params[key] === "") {
        delete params[key];
      }
    });

    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/books/getbycriteria`, {
        params,
      })
      .then((r) => {
        if (r.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_LITERATURE_RESULTSET,
            payload: r.data,
          });
        }
      });
  };
};

export const getBooks = ({ pageCount }) => {
  return (dispatch, getState) => {
    const actionType = isNaN(pageCount)
      ? ACTION_TYPES.LITERATURE_LOADED
      : ACTION_TYPES.LITERATURE_ADDED;
    pageCount = pageCount || 0;
    const params = {
      pageSize: 10,
      pageCount,
      language: getLanguageParam(getState()),
      ...getState().search.literatureFilter,
    };
    Object.keys(params).forEach((key) => {
      if (params[key] === null || params[key] === "") {
        delete params[key];
      }
    });
    const uri =
      params.input && params.input.length > 2
        ? `${process.env.REACT_APP_API_URL}/api/books/getbycriteria`
        : `${process.env.REACT_APP_API_URL}/api/books`;
    return axios.get(uri, { params }).then((r) => {
      if (r.status === 200) {
        dispatch({
          type: actionType,
          payload: r.data,
        });
      }
    });
  };
};
export const getBookSeries = () => {
  return (dispatch) => {
    const params = {
      language: getBrowserLanguage(),
    };

    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/books/getseries`, {
        params: params,
        paramsSerializer: (params) => qs.stringify(params),
      })
      .then((r) => {
        if (r.status === 200) {
          return dispatch({
            type: ACTION_TYPES.BOOK_SERIES_LOADED,
            payload: r.data,
          });
        }
      });
  };
};

export const getBookTopicsByBookCode = async (bookCode) => {
  const params = {
    language: getBrowserLanguage(),
    bookCode: bookCode,
  };
  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/books/gettopics`, { params })
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const getBookTopics = () => {
  return (dispatch) => {
    const params = {
      language: getBrowserLanguage(),
    };
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/books/gettopics`, { params })
      .then((r) => {
        if (r.status === 200) {
          return dispatch({
            type: ACTION_TYPES.BOOK_TOPICS_LOADED,
            payload: r.data,
          });
        }
      });
  };
};

export const getBookLanguages = () => {
  return (dispatch) => {
    const params = {
      language: getBrowserLanguage(),
    };
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/books/getlanguages`, {
        params,
      })
      .then((r) => {
        if (r.status === 200) {
          return dispatch({
            type: ACTION_TYPES.BOOK_LANGUAGES_LOADED,
            payload: r.data,
          });
        }
      });
  };
};

export const getNovelties = (state) => {
  const params = {
    language: getLanguageParam(state),
  };

  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/books/getnovelties`, { params })
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const getAudiosByCriteria = (criteria) => {
  return (dispatch) => {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/audios/getbycriteria?criteria=${criteria}`
      )
      .then((r) => {
        if (r.status === 200) {
          return dispatch({
            type: ACTION_TYPES.SEARCH_AUDIO_RESULTSET,
            payload: r.data,
          });
        }
      });
  };
};

export const getCoursesByCriteria = (criteria) => {
  return (dispatch) => {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/courses/getbycriteria?criteria=${criteria}`
      )
      .then((r) => {
        if (r.status === 200) {
          return dispatch({
            type: ACTION_TYPES.SEARCH_COURSE_RESULTSET,
            payload: r.data,
          });
        }
      });
  };
};

export const openModal = (modalType, params) => {
  return {
    type: ACTION_TYPES.OPEN_MODAL,
    payload: { modalType, params },
  };
};

export const getNotice = () => {
  return (dispatch) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/notice/get`)
      .then((r) => {
        if (r.status === 200) {
          return dispatch({
            type: ACTION_TYPES.NOTICE_LOADED,
            payload: r.data,
          });
        }
      })
      .catch((e) => {
        return dispatch({
          type: ACTION_TYPES.NOTICE_LOADED,
          payload: { id: "1", text: "" },
        });
      });
  };
};

export const getDistributors = () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/distributors`)
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const getFreeBroadCasters = (
  { titleHint, pageCount, language },
  state
) => {
  const params = {
    titleHint,
    take: 10,
    pageCount,
    serie: 5, //FGB
    language: language || getLanguageParam(state),
  };
  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/books/getbooksbyserie`, {
      params,
    })
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const getStudiesInTheScripturesAnnual = ({ pageCount }) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/books/getbooksbyserie?take=10&pagecount=${pageCount}&serie=8`
    )
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const getStudiesInTheScripturesMonthly = (
  { titleHint, pageCount },
  state
) => {
  const params = {
    titleHint,
    take: 10,
    pageCount,
    serie: 31, //SiS Monthly
    language: getLanguageParam(state),
  };
  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/books/getbooksbyserie`, {
      params,
    })
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const getAuthors = ({ pageCount }) => {
  return (dispatch, getState) => {
    const actionType =
      isNaN(pageCount) || pageCount === 0
        ? ACTION_TYPES.AUTHORS_LOADED
        : ACTION_TYPES.AUTHORS_ADDED;

    pageCount = pageCount || 0;
    const params = {
      take: 20,
      pagecount: pageCount,
      language: getBrowserLanguage(),
      ...getState().search.authorsFilter,
    };
    Object.keys(params).forEach((key) => {
      if (params[key] === null || params[key] === "") {
        delete params[key];
      }
    });
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/authors`, { params })
      .then((r) => {
        if (r.status === 200) {
          dispatch({
            type: actionType,
            payload: r.data,
          });
        }
      });
  };
};

export const getAuthor = (code, state) => {
  return axios
    .get(
      `${
        process.env.REACT_APP_API_URL
      }/api/authors/getdetail/${code}?language=${getBrowserLanguage()}`
    )
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const getBooksByAuthor = ({ pageCount, code }, state) => {
  const params = {
    take: 10,
    pageCount,
    code,
    language: getLanguageParam(state),
  };

  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/books/getbooksbyauthor`, {
      params,
    })
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const getAudiosBySerie = (serie, pageCount) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/audios/getaudiosbyserie?take=20&pagecount=${pageCount}&serie=${serie}`
    )
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const getTopDownloads = (state) => {
  const params = {
    language: getLanguageParam(state),
  };
  return axios
    .get(process.env.REACT_APP_API_URL + "/api/books/gettopdownloads", {
      params,
    })
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const getCourses = (state) => {
  const params = {
    languageFilter: state.options.browserFilter ? getBrowserLanguage() : null,
    language: getBrowserLanguage(),
  };

  const url = process.env.REACT_APP_API_URL + "/api/courses/getall";

  return axios.get(url, { params }).then((r) => {
    const unique = [...new Set(r.data.map((item) => item.topic))];
    const result = unique.map((u) => {
      const topic = {
        name: u,
        items: r.data.filter((course) => course.topic === u),
      };
      return topic;
    });
    return result;
  });
};

export const getBook = (code, state) => {
  const params = {
    code,
    language: getBrowserLanguage(),
  };

  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/books/getdetail`, { params })
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const getCourse = (code, state) => {
  const params = {
    code,
    language: getBrowserLanguage(),
  };

  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/courses/getdetail`, { params })
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const addBookToCart = (book) => (dispatch, getState) => {
  const { cart, user } = getState();

  const anyPaperBack = cart.selectedBooks.some(
    (book) => book.type === "Paperbacks"
  );
  const valInCart = sumValueInCart(cart);
  if (
    cart.checkoutForm.type === "free" &&
    cart.selectedBooks.length > 14 &&
    0 + user.dbUser.freeLimit < 21 &&
    cart.selectedBooks.filter((b) => book.code === b.code).length < 1
  ) {
    const message =
      t("validation-cart-limit-count") +
      t("validation-cart-switch", {
        freeLimit: user.dbUser.freeLimit,
      });
    dispatch(sendMessage(message, "", "warning"));
  } else if (
    anyPaperBack &&
    book.type === "Paperbacks" &&
    cart.checkoutForm.type === "free"
  ) {
    const message =
      t("validation-cart-unique-paperback") +
      t("validation-cart-switch", {
        freeLimit: user.dbUser.freeLimit,
      });
    dispatch(sendMessage(message, "", "warning"));
  } else if (
    valInCart > 0 &&
    valInCart + book.price > user.dbUser.freeLimit &&
    cart.checkoutForm.type === "free"
  ) {
    const message =
      t("validation-cart-limit", {
        freeLimit: user.dbUser.freeLimit,
      }) +
      t("validation-cart-switch", {
        freeLimit: user.dbUser.freeLimit,
      });
    dispatch(sendMessage(message, "", "warning"));
  } else {
    if (cart.selectedBooks.length === 0) {
      const modalBody = user.dbUser.alreadyOrderedMo
        ? t("cart-already-ordered")
        : t("cart-first-item-explanation", {
            freeLimit: user.dbUser.freeLimit,
          });
      const modalParams = {
        modalHeader: t("add-to-cart"),
        modalBody,
        closeLabel: t("keep-shopping"),
      };
      dispatch(openModal(2, modalParams));
    }
    return dispatch({
      type: ACTION_TYPES.ADD_BOOK_TO_CART,
      payload: {
        code: book.code,
        title: book.title,
        type: book.type,
        coverUrl: book.coverUrl,
        price: book.price,
        counter: 1,
      },
    });
  }
};
export const changeCartQty = (book) => (dispatch, getState) => {
  const { cart, user } = getState();

  const anyPaperBack = cart.selectedBooks.some(
    (book) => book.type === "Paperbacks"
  );
  const valInCart = sumValueInCart(cart);

  if (
    anyPaperBack &&
    book.type === "Paperbacks" &&
    cart.checkoutForm.type === "free"
  ) {
    const message =
      t("validation-cart-unique-paperback") +
      t("validation-cart-switch", {
        freeLimit: user.dbUser.freeLimit,
      });
    dispatch(sendMessage(message, "", "warning"));
  } else if (
    valInCart + book.price * book.counter > user.dbUser.freeLimit &&
    cart.checkoutForm.type === "free"
  ) {
    const message =
      t("validation-cart-limit", {
        freeLimit: user.dbUser.freeLimit,
      }) +
      t("validation-cart-switch", {
        freeLimit: user.dbUser.freeLimit,
      });
    dispatch(sendMessage(message, "", "warning"));
  } else {
    return dispatch({
      type: ACTION_TYPES.CHANGE_CART_QTY,
      payload: {
        code: book.code,
        title: book.title,
        type: book.type,
        coverUrl: book.coverUrl,
        price: book.price,
        counter: book.counter,
      },
    });
  }
};

export const removeBookFromCart =
  (book, all = false) =>
  (dispatch) => {
    if (all) {
      dispatch({
        type: ACTION_TYPES.REMOVE_BOOK_FROM_CART,
        payload: {
          code: book.code,
          all,
        },
      });
    }

    return dispatch({
      type: ACTION_TYPES.REMOVE_BOOK_FROM_CART,
      payload: {
        code: book.code,
        title: book.title,
        type: book.type,
        coverUrl: book.coverUrl,
        price: book.price,
        counter: book.counter,
      },
    });
  };

export const canAddToCart = (book, state) => {
  const { cart } = state;
  return !cart.selectedBooks.some((r) => r.code === book.code);
};

export const changeSelectedBook = (book) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.SELECTED_BOOK_CHANGED,
    payload: book,
  });

export const enableBrowserFilter = (enabled) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.BROWSER_FILTER_CHANGE,
    payload: enabled,
  });

export const countBooksInCart = ({ selectedBooks }) =>
  selectedBooks.reduce((acc, value) => acc + value.counter, 0);
export const sumValueInCart = ({ selectedBooks }) =>
  selectedBooks.reduce((acc, value) => acc + value.lineVal, 0);

export const changeCheckoutForm = (contact) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.CHANGE_CHECKOUT_FORM,
    payload: contact,
  });

export const changeNewAddressForm = (contact) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.CHANGE_NEW_ADDRESS_FORM,
    payload: contact,
  });

export const changeUserForm = (user) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.CHANGE_USER_FORM,
    payload: user,
  });

export const updateDbUserOrderStatus = (dbUser, newLastOrderDate = null) => {
  let _dbUser = dbUser;
  const date = new Date();
  const moShort =
    date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2);

  if (newLastOrderDate === "today") {
    _dbUser["lastOrderDate"] = moShort;
  } else if (newLastOrderDate !== null) {
    _dbUser["lastOrderDate"] = newLastOrderDate;
  }

  const alreadyOrderedMo =
    typeof _dbUser.lastOrderDate !== "undefined"
      ? _dbUser.lastOrderDate.substring(0, 7) === moShort
      : false;

  _dbUser["alreadyOrderedMo"] = alreadyOrderedMo;
  _dbUser["freeLimit"] = alreadyOrderedMo ? 0 : _dbUser["freeLimit"];
  return _dbUser;
};

export const findUserByExternalId = (externalid, token) => {
  return (dispatch, getState) => {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/users/getuserbyexternalid?externalid=${externalid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((r) => {
        if (r.status === 200) {
          dispatch({
            type: ACTION_TYPES.USER_LOADED,
            payload: updateDbUserOrderStatus(r.data),
          });
          const { cart, user } = getState();
          if (user.dbUser.alreadyOrderedMo) {
            let form = cart.checkoutForm;
            form["type"] = "pay";
            dispatch(changeCheckoutForm(form));
          }
        }
      })
      .catch((e) => {
        return dispatch({
          type: ACTION_TYPES.USER_LOADED,
          payload: { freeLimit: 20, firstName: "", regularGivings: [] },
        });
      });
  };
};
export const getOrders = (token, userId) => {
  return (dispatch) => {
    const params = {
      take: 20,
      pageCount: 0,
      userId,
    };

    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/orders/getorders`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((r) => {
        if (r.status === 200) {
          dispatch({
            type: ACTION_TYPES.ORDERS_LOADED,
            payload: r.data,
          });
        }
      });
  };
};

export const getUserAddresses = (token, userId) => {
  return (dispatch) => {
    const params = {
      userId,
    };

    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/addresses/getuseraddresses`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((r) => {
        if (r.status === 200) {
          dispatch({
            type: ACTION_TYPES.ADDRESSES_LOADED,
            payload: r.data,
          });
        }
      });
  };
};
export const resendVerificationEmail = (token, externalId) => {
  const params = {
    externalId,
  };
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/users/resendVerificationEmail`,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((r) => {
      if (r.status === 200) {
        return "Ok.";
      }
    });
};
export const sendPasswordResetEmail = (email) => {
  const params = {
    email,
    client_id: config.clientId,
    connection: "Username-Password-Authentication",
  };
  return axios
    .post(
      "https://chapellibrary.auth0.com/dbconnections/change_password",
      params
    )
    .then((r) => {
      if (r.status === 200) {
        return "Ok.";
      }
    });
};
export const signupPrintSubscription = (token, subscriptionId) => {
  const params = {};
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/usersubscriptions?subscriptionId=` +
        subscriptionId,
      params,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const setDefaultUserAddress = (token, userId, addressId) => {
  return (dispatch, getState) => {
    const params = {
      userId,
      addressId,
    };

    return axios
      .post(
        `${
          process.env.REACT_APP_API_URL
        }/api/users/setdefaultaddress?culture=${getLanguageParam(getState())}`,
        params,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((r) => {
        if (r.status === 200) {
          const { user } = getState();
          const { dbUser } = user;
          dbUser.defaultAddress = r.data.addressId;
          dispatch({
            type: ACTION_TYPES.USER_LOADED,
            payload: dbUser,
          });
        }
      });
  };
};
export const getCountries = () =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/locations/countries`)
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });

export const getRegions = (countryCode) =>
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/locations/regionsByCountry?countryId=${countryCode}`
    )
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });

export const sendMessage =
  (text, header = "", type = "success") =>
  (dispatch) =>
    dispatch({
      type: ACTION_TYPES.NEW_MESSAGE,
      payload: {
        text,
        header,
        type,
        timestamp: new Date(),
      },
    });

export const setToken = (token) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.TOKEN_LOADED,
    payload: token,
  });

export const clearCart = () => (dispatch, getState) => {
  const { cart } = getState();
  let payCart = cart.checkoutForm.type === "pay";
  dispatch({
    type: ACTION_TYPES.CLEAN_CART,
  });
  let form = cart.checkoutForm;
  if (payCart) {
    form["type"] = "pay";
  }
  form["donation"] = 0;
  dispatch(changeCheckoutForm(form));
};

export const saveCartOrder = () => (dispatch, getState) => {
  const { cart, user } = getState();
  const { selectedBooks, checkoutForm } = cart;
  const { token } = user;
  const valInCart = sumValueInCart(cart);

  const data = {
    bookOrders: selectedBooks
      .filter((r) => r.counter > 0)
      .map((book) => ({
        bookCode: book.code,
        quantity: book.counter,
      })),
    userId: checkoutForm.userId,
    addressId: checkoutForm.addressId,
    fgbEnSubscriber: !!checkoutForm.fgben,
    fgbEsSubscriber: !!checkoutForm.fgbes,
    catalogEnSubscriber: !!checkoutForm.caten,
    catalogEsSubscriber: !!checkoutForm.cates,
    type: checkoutForm.type,
    donation: checkoutForm.donation,
    payAmount: Math.max(valInCart - user.dbUser.freeLimit, 0).toFixed(2),
    cartValue: valInCart.toFixed(2),
    paymentId: checkoutForm.paymentId,
    ipAddress: checkoutForm.ipAddress,
  };

  const freeOrder =
    (data.cartValue || 0) - (data.donation || 0) - (data.payAmount || 0) > 0.5;

  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/orders?culture=${getLanguageParam(
        getState()
      )}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((r) => {
      if (r.status === 200) {
        dispatch(
          sendMessage(t("cart-posted-success"), t("cart-thank-you"), "success")
        );

        dispatch({
          type: ACTION_TYPES.CLEAN_CART,
        });

        dispatch({
          type: ACTION_TYPES.CART_POSTED,
          payload: r.data,
        });

        if (freeOrder) {
          dispatch({
            type: ACTION_TYPES.USER_LOADED,
            payload: updateDbUserOrderStatus(user.dbUser, "today"),
          });
        }
        if (user.dbUser.alreadyOrderedMo) {
          let form = cart.checkoutForm;
          form["type"] = "pay";
          form["donation"] = 0;
          dispatch(changeCheckoutForm(form));
        }
      }
    })
    .catch((error) => {
      dispatch(
        sendMessage(
          t("cart-posted-error") + " " + error.response.data,
          "",
          "danger"
        )
      );
      let form = cart.checkoutForm;
      form["paymentId"] = "";
      dispatch(changeCheckoutForm(form));
    });
};
export const saveDonateOrder = () => (dispatch, getState) => {
  const { cart, user } = getState();
  const { checkoutForm } = cart;
  const { token } = user;

  const data = {
    userId: checkoutForm.userId,
    addressId: checkoutForm.addressId,
    donation: checkoutForm.donation,
    paymentId: checkoutForm.paymentId,
    recurringInterval: checkoutForm.interval,
    ipAddress: checkoutForm.ipAddress,
  };

  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/orders?culture=${getLanguageParam(
        getState()
      )}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((r) => {
      if (r.status === 200) {
        dispatch(
          sendMessage(t("cart-posted-success"), t("cart-thank-you"), "success")
        );

        let form = cart.checkoutForm;
        form["donation"] = 0;
        form["paymentId"] = "";
        dispatch(changeCheckoutForm(form));

        dispatch({
          type: ACTION_TYPES.CART_POSTED,
          payload: r.data,
        });
      }
    })
    .catch((error) => {
      dispatch(
        sendMessage(
          t("cart-posted-error") + " " + error.response.data,
          "",
          "danger"
        )
      );
    });
};
export const saveRegularGiving = () => (dispatch, getState) => {
  const { cart, user } = getState();
  const { checkoutForm } = cart;
  const { token } = user;

  const data = {
    userId: checkoutForm.userId,
    addressId: checkoutForm.addressId,
    donation: checkoutForm.donation,
    paymentId: checkoutForm.paymentId,
    recurringInterval: checkoutForm.interval,
    transactionId: checkoutForm.transactionId,
    ipAddress: checkoutForm.ipAddress,
  };

  return axios
    .post(`${process.env.REACT_APP_API_URL}/api/regulargivings`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((r) => {
      if (r.status === 200) {
        let form = cart.checkoutForm;
        form["donation"] = 0;
        form["paymentId"] = "";
        dispatch(changeCheckoutForm(form));

        dispatch({
          type: ACTION_TYPES.REGULARGIVING_ADDED,
          payload: r.data,
        });
        dispatch({
          type: ACTION_TYPES.CART_POSTED,
          payload: r.data,
        });
      }
    })
    .catch((error) => {
      dispatch(
        sendMessage(
          t("cart-posted-error") + " " + error.response.data,
          "",
          "danger"
        )
      );
    });
};
export const saveDonateOrderGuest = () => (dispatch, getState) => {
  const { cart } = getState();
  const { checkoutForm } = cart;

  const data = {
    email: checkoutForm.email,
    phone: checkoutForm.phone,
    firstName: checkoutForm.firstName,
    lastName: checkoutForm.lastName,
    zipCode: checkoutForm.zipcode,
    donation: checkoutForm.donation,
    paymentId: checkoutForm.paymentId,
    ipAddress: checkoutForm.ipAddress,
    captchaValue: checkoutForm.captchaValue,
  };

  return axios
    .post(
      `${
        process.env.REACT_APP_API_URL
      }/api/orders/guest?culture=${getLanguageParam(getState())}`,
      data
    )
    .then((r) => {
      if (r.status === 200) {
        dispatch(
          sendMessage(t("cart-posted-success"), t("cart-thank-you"), "success")
        );

        let form = cart.checkoutForm;
        form["donation"] = 0;
        form["paymentId"] = "";
        dispatch(changeCheckoutForm(form));
        dispatch({
          type: ACTION_TYPES.CART_POSTED,
          payload: r.data,
        });
      }
    })
    .catch((error) => {
      dispatch(
        sendMessage(
          t("cart-posted-error") + " " + error.response.data,
          "",
          "danger"
        )
      );
    });
};
export const saveUserAddress = (token) => (dispatch, getState) => {
  const { user } = getState();
  const { newAddress } = user;

  const data = {
    userId: user.dbUser.userId,
    regionId: newAddress.regionId,
    cityName: newAddress.cityName.trim(),
    address: newAddress.address.trim(),
    zipCode: newAddress.zipcode.trim(),
    organization: newAddress.organization ? newAddress.organization.trim() : "",
    taxId: newAddress.taxId ? newAddress.taxId.trim() : "",
  };

  return axios
    .post(`${process.env.REACT_APP_API_URL}/api/addresses`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((r) => {
      if (r.status === 200) {
        dispatch(sendMessage(t("cart-posted-success"), "", "success"));

        dispatch({
          type: ACTION_TYPES.CLEAN_NEW_ADDRESS_FORM,
        });

        dispatch({
          type: ACTION_TYPES.ADDRESSES_LOADED,
          payload: r.data,
        });
      }
    })
    .catch((error) => {
      dispatch(sendMessage(t("cart-posted-error"), "", "danger"));

      dispatch({
        type: ACTION_TYPES.CLEAN_NEW_ADDRESS_FORM,
      });
    });
};

export const saveUser = (token) => (dispatch, getState) => {
  const { user, cart } = getState();
  const { userForm } = user;

  const data = {
    email: userForm.email.trim(),
    phone: userForm.phone.trim(),
    externalId: userForm.externalId,
    firstName: userForm.firstName.trim(),
    lastName: userForm.lastName.trim(),
    userId: userForm.userId,
    defaultAddress: userForm.defaultAddress,
  };

  return axios
    .post(`${process.env.REACT_APP_API_URL}/api/users`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((r) => {
      if (r.status === 200) {
        dispatch(sendMessage(t("cart-posted-success"), "", "success"));

        dispatch({
          type: ACTION_TYPES.CLEAN_USER_FORM,
        });

        dispatch({
          type: ACTION_TYPES.USER_LOADED,
          payload: updateDbUserOrderStatus(r.data),
        });
        if (user.dbUser.alreadyOrderedMo) {
          let form = cart.checkoutForm;
          form["type"] = "pay";
          dispatch(changeCheckoutForm(form));
        }
      }
    })
    .catch((error) => {
      dispatch(sendMessage(t("cart-posted-error"), "", "danger"));

      dispatch({
        type: ACTION_TYPES.CLEAN_USER_FORM,
      });
    });
};

export const deleteAddress = (addressId) => (dispatch, getState) => {
  const data = {
    addressId,
  };
  const { user } = getState();
  const { token } = user;

  return axios
    .post(`${process.env.REACT_APP_API_URL}/api/addresses/remove`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((r) => {
      if (r.status === 200) {
        dispatch({
          type: ACTION_TYPES.ADDRESSES_LOADED,
          payload: r.data,
        });
      }
    })
    .catch((error) => {
      dispatch(sendMessage(t("cart-posted-error"), "", "danger"));
    });
};

export const cancelRegularGiving = (id) => (dispatch, getState) => {
  const { user } = getState();
  const { token } = user;

  return axios
    .delete(`${process.env.REACT_APP_API_URL}/api/regulargivings?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((r) => {
      if (r.status === 200) {
        dispatch({
          type: ACTION_TYPES.REGULARGIVING_CANCELLED,
          payload: id,
        });
      }
    })
    .catch((error) => {
      dispatch(sendMessage(t("cart-posted-error"), "", "danger"));
    });
};

export const getYoutubePlaylist = () =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/videos/getyoutubeplaylist`)
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });

export const changeSubscriptionsForm = (contact) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.CHANGE_SUBSCRIPTIONS_FORM,
    payload: contact,
  });

export const getSubcriptions = (type = "all") =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/subscriptions?type=` + type)
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });

export const getUserSubscriptions = (type, state) => {
  const { user } = state;
  const { token } = user;
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/usersubscriptions?type=` + type,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });
};

export const saveSubscription = () => (dispatch, getState) => {
  const { subscriptions } = getState();
  const { form } = subscriptions;

  const data = {
    email: form.email.trim(),
    firstName: form.firstName.trim(),
    lastName: form.lastName.trim(),
    regionId: form.regionId,
    subscriptionIds: form.subscriptions,
  };

  return axios
    .post(`${process.env.REACT_APP_API_URL}/api/subscriptions`, data)
    .then((r) => {
      if (r.status === 200) {
        dispatch(sendMessage(t("cart-posted-success"), "", "success"));

        dispatch({
          type: ACTION_TYPES.CLEAN_SUBSCRIPTION,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: ACTION_TYPES.CLEAN_SUBSCRIPTION,
      });
    });
};

export const findFile = (bookName) =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/books/find?book=${bookName}`)
    .then((r) => {
      if (r.status === 200) {
        return r.data;
      }
    });

export const changeAuthorsSort = (sort) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.SORT_AUTHOR_CHANGE,
    payload: sort,
  });

export const changeSearchCriteria =
  (criteria, stateHandler, actionType) => (dispatch, getState) => {
    dispatch({
      type: ACTION_TYPES[actionType],
      payload: { ...getState().search[stateHandler], ...criteria },
    });
  };
